import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import React, { useMemo } from 'react';
import { ActionStep, PropertyType, RecordType, TriggerStep, ValidationInputType } from '@types';
import { useAutomationTriggersAndActions } from '@hooks/automations/useAutomationTriggersAndActions';
import { Action, AutomationStepType, InputType, StepFieldKeys } from '@enums';
import { useCreateAutomationStep } from '@hooks/automations/useCreateAutomationStep';
import { useUpdateAutomationStep } from '@hooks/automations/useUpdateAutomationStep';
import {
  clearQuillValue,
  clearQuillValueForEmailsList,
  clearQuillValueForSms,
  convertQuillFormatToText,
  convertTextToQuillFormat
} from '@utils/quill';
import { identity, isPlainObject, toString, trim } from 'lodash';
import { convertFromTemplateMemberIdToPropertyId, isStage } from '@utils/properties';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useUpdateEffect } from '@react-hookz/web';
import { UseFormReturn } from 'react-hook-form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useCheckAction } from '@hooks/automations/useCheckAction';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { Check, X } from 'react-feather';
import { defaultTheme } from '@themes';

import { TypeTweaks } from '@features/Platform/Automations/Form/Field/Field';
import { useRecordType } from '@features/Platform/Automations/Form/useRecordType';
import { validateUrl } from '@utils/validations';
import { Field } from '../Field';
import { Panel } from '../Panel';
import { Container, FooterActions } from './styled';

const TOKENIZED_FIELDS = [
  StepFieldKeys.BODY,
  StepFieldKeys.TO_EMAIL,
  StepFieldKeys.CC,
  StepFieldKeys.BCC,
  StepFieldKeys.SUBJECT,
  StepFieldKeys.PHONE,
  StepFieldKeys.MESSAGE,
  StepFieldKeys.DESCRIPTION
];

const isTokenizedField = (key: StepFieldKeys) => TOKENIZED_FIELDS.includes(key);

export type ActionFormValues = {
  action: ActionStep;
} & {
  [fieldKey in StepFieldKeys]?: any;
};

interface Props {
  automationId: number;
  step?: ActionStep;
  trigger: TriggerStep;
  triggerId: number;
}

const getInitialValues = ({ step, actions }: { step?: ActionStep; actions: ActionStep[] }) => {
  const action = actions.find((act) => act.key === step?.key) ?? actions[0];

  return {
    action,
    ...step?.fields.reduce((acc, field) => {
      const fieldDefinition = action.fields.find((actionField) => actionField.key === field.key);

      if (fieldDefinition?.type === InputType.USER_SELECTOR) {
        const processSingleValue = (id: number | string | null) => {
          if (!id) {
            return null;
          }

          if (typeof id === 'string' && id.startsWith('property.')) {
            const parsedId = parseInt(id.replace('property.', ''), 10);

            return parsedId;
          }

          return id;
        };

        const fieldConfig = action.fields.find((actionField) => actionField.key === field.key);

        acc[field.key] = fieldConfig?.allowMultiple
          ? (field.value ?? []).map(processSingleValue).filter(Boolean)
          : processSingleValue(field.value);
      } else if (isTokenizedField(field.key)) {
        acc[field.key] = convertTextToQuillFormat(field.value ?? '');
      } else {
        acc[field.key] = field.value;
      }

      return acc;
    }, {} as any)
  };
};

const DEFAULT_VALUES_BY_FIELD_KEY: { [key in StepFieldKeys]?: any } = {
  [StepFieldKeys.DUE_DATE_UNIT]: 'days',
  [StepFieldKeys.DUE_DATE_NUMBER]: 0
};

export const ActionForm = ({ step, automationId, trigger, triggerId }: Props) => {
  const { data: { actions } = { actions: [] as ActionStep[] } } = useAutomationTriggersAndActions();

  const { mutateAsync: createAutomationStep } = useCreateAutomationStep();
  const { mutateAsync: updateAutomationStep } = useUpdateAutomationStep();
  const { mutateAsync: checkAction, data: webhookTestIsOk, isLoading } = useCheckAction();

  const getFieldsAndKey = (values: ActionFormValues) => ({
    key: values.action?.key,
    fields: values.action?.fields.map((field) => {
      if ([StepFieldKeys.TO_EMAIL, StepFieldKeys.PHONE].includes(field.key)) {
        return {
          key: field.key,
          value: clearQuillValueForEmailsList(clearQuillValue(convertQuillFormatToText(values[field.key] || '')))
            .split(',')
            .map(trim)
        };
      }

      if (isTokenizedField(field.key)) {
        const isMessageOrSubject = [StepFieldKeys.MESSAGE, StepFieldKeys.SUBJECT].includes(field.key);
        const isSmsAction = values.action?.key === Action.SEND_SMS;

        let clearFunction = identity;

        if (isMessageOrSubject) {
          clearFunction = isSmsAction ? clearQuillValueForSms : clearQuillValue;
        }

        if ([StepFieldKeys.CC, StepFieldKeys.BCC].includes(field.key)) {
          return {
            key: field.key,
            value: clearQuillValueForEmailsList(clearFunction(convertQuillFormatToText(values[field.key] || '')))
          };
        }

        return {
          key: field.key,
          value: clearFunction(convertQuillFormatToText(values[field.key] || ''))
        };
      }

      if (field.type === InputType.USER_SELECTOR) {
        const processSingleValue = (user: number | { id: number }) => {
          if (!user) {
            return null;
          }

          const id = typeof user === 'number' ? user : user.id;

          return id < 0 || (typeof user !== 'number' && user?.type === 'property')
            ? `property.${convertFromTemplateMemberIdToPropertyId(id)}`
            : id;
        };

        return {
          key: field.key,
          value: field.allowMultiple
            ? ((values[field.key] as ({ id: number } | number)[]) ?? []).map(processSingleValue).filter(Boolean)
            : processSingleValue(values[field.key])
        };
      }

      if (field.allowMultiple) {
        return {
          key: field.key,
          value: toString(values[field.key])
            .split(',')
            .map((value) => value.trim())
        };
      }

      let value = values[field.key];

      if (isPlainObject(value)) {
        value = value.id ?? value.key;
      }

      return {
        key: field.key,
        value
      };
    })
  });

  const [recordType] = useRecordType();

  const allowedActions = useMemo(
    () =>
      actions.filter((action) => {
        if (action.allowedTriggers && !action.allowedTriggers.includes(trigger.key)) {
          return false;
        }

        switch (action.key) {
          case Action.UPDATE_PROPERTY:
            if (!recordType) {
              return false;
            }
            break;

          case Action.CREATE_TASK:
            return [RecordType.PROJECT, RecordType.DEAL].includes(recordType);

          default:
        }

        return true;
      }),
    [actions, trigger, recordType]
  );

  const postForm = async (values: ActionFormValues, { reset }: UseFormReturn<ActionFormValues>) => {
    const payload = {
      automationId,
      stepId: step?.id ?? undefined,
      req: {
        parentId: step?.id ? undefined : triggerId,
        type: AutomationStepType.ACTION,
        ...getFieldsAndKey(values)
      }
    };

    await (step?.id ? updateAutomationStep(payload) : createAutomationStep(payload));

    reset(undefined, { keepDirty: false, keepValues: true, keepDirtyValues: true, keepIsValid: true });
  };

  const { handleSubmit, form } = useForm<ActionFormValues>({
    onSubmit: postForm,
    defaultValues: getInitialValues({ step, actions })
  });

  const {
    control,
    watch,
    setValue,
    reset,
    formState: { isDirty }
  } = form;

  const action: ActionStep = watch('action');

  const { editableColumns, scopeToEditableColumns } = useCompanyProperties();

  const allowedColumns = useMemo(
    () => (scopeToEditableColumns[recordType] || []).filter((property) => !isStage(property)),
    [scopeToEditableColumns, recordType]
  );

  const selectedPropertyId = watch(StepFieldKeys.COLUMN_ID) as number;

  const selectedProperty = useMemo(
    () => editableColumns.find((column) => column.id === selectedPropertyId),
    [editableColumns, selectedPropertyId]
  );

  const rules = useMemo<FormValidationRules<ActionFormValues>>(
    () => ({
      action: {
        isRequired: true
      },
      ...action?.fields.reduce((acc, field) => {
        if (field.required && field.type !== InputType.CHECKBOX) {
          acc[field.key] = {
            isRequired: true
          };
        }

        if (field.validationType === ValidationInputType.URL) {
          acc[field.key] = {
            isRequired: field.required,
            validate: validateUrl
          };
        }

        if (action.key === Action.UPDATE_PROPERTY && field.key === StepFieldKeys.COLUMN_VALUE) {
          if (selectedProperty?.type === PropertyType.Link) {
            acc[field.key] = {
              isRequired: field.required,
              validate: validateUrl
            };
          }
        }

        return acc;
      }, {} as FormValidationRules<ActionFormValues>)
    }),
    [action, selectedProperty]
  );

  useUpdateEffect(() => {
    setValue(StepFieldKeys.COLUMN_VALUE, null);
  }, [selectedPropertyId]);

  useUpdateEffect(() => {
    reset({
      action,
      ...action?.fields.reduce(
        (acc, field) => ({ ...acc, [field.key]: DEFAULT_VALUES_BY_FIELD_KEY[field.key] ?? null }),
        {}
      )
    });
  }, [action?.key]);

  const buttons = useMemo(() => {
    return action?.fields.filter((field) => field.type === InputType.TEST_BUTTON) ?? [];
  }, [action?.fields]);

  const fields = useMemo(() => {
    return action?.fields.filter((field) => field.type !== InputType.TEST_BUTTON) ?? [];
  }, [action?.fields]);

  const fieldsTypeTweaks = useMemo<TypeTweaks>(
    () => ({
      [InputType.PROPERTY_SELECTOR]: { columns: allowedColumns },
      [InputType.PROPERTY_INPUT]: { column: selectedProperty }
    }),
    [allowedColumns, selectedProperty]
  );

  return (
    <Container>
      <Form rules={rules} onSubmit={handleSubmit}>
        <Panel title="Action" description="An event that follows automatically">
          <SelectField
            name="action"
            label="Action"
            options={allowedActions}
            control={control}
            isClearable={false}
            getOptionLabel={(option: ActionStep) => option.name}
            getOptionValue={(option: ActionStep) => option.key}
            getOptionSelected={(option: ActionStep, value: ActionStep) => option.key === value.key}
          />

          {fields.map((field) => (
            <div key={field.key}>
              <Field
                recordType={recordType}
                control={control}
                field={field}
                typeTweaks={fieldsTypeTweaks}
                area="action"
              />

              <div dangerouslySetInnerHTML={{ __html: field.description }} />
            </div>
          ))}

          <FooterActions>
            {buttons.map((button) => (
              <Tooltip key={button.key} title={<div dangerouslySetInnerHTML={{ __html: button.description }} />}>
                <Button
                  variant={ButtonVariant.Secondary}
                  disabled={isLoading}
                  onClick={async () => setValue(button.key, await checkAction(getFieldsAndKey(form.getValues())))}
                >
                  {isLoading && <CircularProgress color={defaultTheme.colors.black} size={16} />}
                  {!isLoading && webhookTestIsOk === true && <Check color={defaultTheme.colors.green} size={16} />}
                  {!isLoading && webhookTestIsOk === false && <X color={defaultTheme.colors.red} size={16} />}
                  Test webhook
                </Button>
              </Tooltip>
            ))}
            <Button disabled={!isDirty || isLoading} type="submit" variant={ButtonVariant.Primary}>
              Save
            </Button>
          </FooterActions>
        </Panel>
      </Form>
    </Container>
  );
};
